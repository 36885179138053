import { useEffect, useState } from "react"
import bscstakeAbi from '../json/bscstake.json';
import tokenAbi from '../json/token.json';
import { contract } from "./constant";
import { MulticallContractWeb3 } from "./useContracts";
import { getWeb3 } from "./connectors";
import { BSC_CHAIN_ID } from "./constant";
import { toast } from "react-toastify";
import { useAccount } from 'wagmi';

export const useAccountStats = (updater) => {
    let { address } = useAccount()
    let web3 = getWeb3();

    const [stats, setStats] = useState({
        totalStakedTokenUser: 0,
        totalUnstakedTokenUser: 0,
        totalClaimedRewardTokenUser: 0,
        stakeCount: 0,
        alreadyExists: false,
        totalStakeHistory: [],
        stakeTokenDecimals: 0,
        stakeTokenBalance: 0,
        currentStake: 0,
        isApproved: 0,
        rewardHistory: [],
        referrer: 0,
        reward: 0,
        referredCount: 0,
        refLevelCount: [0, 0, 0, 0, 0]
    });

    const mc = MulticallContractWeb3(BSC_CHAIN_ID);
    let stakeContract = new web3.eth.Contract(bscstakeAbi, contract[BSC_CHAIN_ID].STAKE_ADDRESS);



    useEffect(() => {
        const fetch = async () => {
            try {
                let stakeInfoArray = [];
                let rewardArray = [];
                const data = await mc.aggregate([
                    stakeContract.methods.Stakers(address), //0
                    stakeContract.methods.stakeToken(), //1
                    stakeContract.methods.accounts(address), //2
                    stakeContract.methods.getlevelrefcount(address) //3
                ]);

                let stakeTokenContract = new web3.eth.Contract(tokenAbi, data[1]);
                

                const tokenData = await mc.aggregate([
                    stakeTokenContract.methods.decimals(),
                    stakeTokenContract.methods.balanceOf(address),
                    stakeTokenContract.methods.allowance(address, contract[BSC_CHAIN_ID].STAKE_ADDRESS)
                ]);

                console.log(tokenData)

                if (parseInt(data[0][3]) > 0) {

                    let callArray = [];
                    let rcallArray = [];
                    for (let i = 0; i < data[0][3]; i++) {
                        callArray[i] = stakeContract.methods.stakersRecord(address, i);
                        rcallArray[i] = stakeContract.methods.realtimeRewardPerBlock(address, i);
                    }

                    stakeInfoArray = await mc.aggregate(callArray);
                    rewardArray = await mc.aggregate(rcallArray);
                }

                setStats({
                    totalStakedTokenUser: data[0][0] / Math.pow(10, tokenData[0]),
                    totalUnstakedTokenUser: data[0][1] / Math.pow(10, tokenData[0]),
                    totalClaimedRewardTokenUser: data[0][2] / Math.pow(10, tokenData[0]),
                    stakeCount: data[0][3],
                    alreadyExists: data[0][4],
                    totalStakeHistory: stakeInfoArray,
                    rewardHistory: rewardArray,
                    stakeTokenDecimals: tokenData[0],
                    stakeTokenBalance: tokenData[1] / Math.pow(10, tokenData[0]),
                    currentStake: (parseFloat(data[0][0]) - parseFloat(data[0][1])) / Math.pow(10, tokenData[0]),
                    isApproved: tokenData[2] / Math.pow(10, tokenData[0]),
                    referrer: data[2][0],
                    reward: data[2][1] / Math.pow(10, tokenData[0]),
                    referredCount: data[2][2],
                    refLevelCount: data[3]
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        if (mc && address) {
            fetch();
        }
        else {
            setStats({
                totalStakedTokenUser: 0,
                totalUnstakedTokenUser: 0,
                totalClaimedRewardTokenUser: 0,
                stakeCount: 0,
                alreadyExists: false,
                totalStakeHistory: [],
                stakeTokenDecimals: 0,
                rewardTokenDecimals: 0,
                stakeTokenBalance: 0,
                currentStake: 0,
                isApproved: 0,
                rewardHistory: [],
                referrer: 0,
                reward: 0,
                referredCount: 0,
                refLevelCount: [0, 0, 0, 0, 0]
            })
        }
        // eslint-disable-next-line
    }, [updater, address]);

    return stats;
}
