import moment from "moment";

export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
}

export const contract = {
  97: {
    STAKE_ADDRESS: "0xf7999b3d63504701c64daf4aac05aee080c88ada",
    MULTICALL_ADDRESS: "0xa54fe4a3dbd1eb21524cd73754666b7e13b4cb18"
  },
  56: {
    STAKE_ADDRESS: "0x506E6275B8F6Cca4fb3B9B58E31a6a19e2dAb909",
    MULTICALL_ADDRESS: "0xcf591ce5574258ac4550d96c545e4f3fd49a74ec"
  }
}

export const PROJECT_ID = "27b9720018bf7f8740936e6a6eb28604";
export const DEFAULT_RPC = "https://bsc-dataseed1.binance.org/"
// export const DEFAULT_RPC = "https://data-seed-prebsc-2-s2.bnbchain.org:8545"
export const BSC_CHAIN_ID = 56;
export const INIT_USER = 140
export const INIT_AMOUNT = 200000000
export const BSC_STAKE_APY =
  [
    {
      apy: "5",
      time: "30"
    },
    {
      apy: "12",
      time: "60 "
    },
    {
      apy: "24",
      time: "90"
    },
    {
      apy: "40",
      time: "120"
    }
  ]


export const formatDateWithZone = (unixTime, onlydate = false) => {
  try {
    if (onlydate) {
      let dateString = moment(unixTime).format("LL");
      return dateString;
    }
    else {
      let dateString = moment(unixTime).format('lll');
      return dateString;
    }
  }
  catch (err) {
    console.log(err.message);
  }

}


export const FAQs = [
  {
    items: "How do I stake?",
    value: "Get CHC Token on your decentralized wallet and connect it with Dapp. Enter the CHC you want to stake. Make sure to keep some BNB in your wallet for the gas fees."
  },
  {
    items: "What is the minimum stake?",
    value: "1 Million CHC token is the minimum to stake."
  },
  {
    items: "What is the maximum stake?",
    value: "There is no Maximum."
  },
  {
    items: "What are time duration of the stake?",
    value: "Stakers can choose from 30 days, 60 days, 90 days and 120 days."
  },
  {
    items: "What is the difference between the 4 durations?",
    value: "There will be a big advantage if you stake longer duration, the longer the duration the higher the interest rate. 5% monthly interest for 30 days stake, 6% monthly interest for 60 days stake, 8% monthly interest rate for 90 days stake and 10% monthly interest for 120 days stake."
  },
  {
    items: "What will happen when I unstake?",
    value: "The stake amount will be transferred back to your wallet."
  },
  {
    items: "What is harvest?",
    value: "A harvest is claiming your rewards and transferring it to your wallet."
  },
  {
    items: "Is there a referral rewards for this?",
    value: "Yes, if you promote, you can get referral bonuses up to 5 levels."
  },
  {
    items: "How much do I earn in referral bonuses?",
    value: "You will earn the following, at level 1 = 2%, Level 2 = 1%, Level 3 = .5%, Level 4 = .5% and on level 5 = .5%."
  },
  {
    items: "What is the team's suggested strategy?",
    value: "The best strategy is to stake your tokens for 120 days, to get the maximum interest. By doing this you will overcome deflation."
  }
];
