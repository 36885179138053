import { useEffect, useState } from "react"
import bscstakeAbi from '../json/bscstake.json';
import tokenAbi from '../json/token.json';
import { contract } from "./constant";
import { MulticallContractWeb3 } from "./useContracts";
import { getWeb3 } from "./connectors";
import { BSC_CHAIN_ID } from "./constant";
import { toast } from "react-toastify";


export const useCommonStats = (updater) => {
    let web3 = getWeb3();


    const [stats, setStats] = useState({
        maxStakeableToken: 0,
        minimumStakeToken: 0,
        totalClaimedRewardToken: 0,
        totalStakedToken: 0,
        totalStakers: 0,
        totalUnStakedToken: 0,
        stakeTokenAddress: '',
        stakeTokenSymbol: '',
        stakeTokenDecimals: 0,

    });

    const mc = MulticallContractWeb3();
    let stakeContract = new web3.eth.Contract(bscstakeAbi, contract[BSC_CHAIN_ID].STAKE_ADDRESS);



    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await mc.aggregate([
                    stakeContract.methods.maxStakeableToken(), //0
                    stakeContract.methods.minimumStakeToken(), //1
                    stakeContract.methods.totalClaimedRewardToken(), //2
                    stakeContract.methods.totalStakedToken(), //3
                    stakeContract.methods.totalStakers(), //4
                    stakeContract.methods.totalUnStakedToken(), //5
                    stakeContract.methods.stakeToken() //6
                ]);

                let stakeTokenContract = new web3.eth.Contract(tokenAbi, data[6]);

                const tokenData = await mc.aggregate([
                    stakeTokenContract.methods.symbol(),
                    stakeTokenContract.methods.decimals()
                ]);



                setStats({
                    maxStakeableToken: data[0] / Math.pow(10, tokenData[1]),
                    minimumStakeToken: data[1] / Math.pow(10, tokenData[1]),
                    totalClaimedRewardToken: data[2] / Math.pow(10, tokenData[1]),
                    totalStakedToken: data[3] / Math.pow(10, tokenData[1]),
                    totalStakers: data[4],
                    totalUnStakedToken: data[5] / Math.pow(10, tokenData[1]),
                    stakeTokenAddress: data[6],
                    stakeTokenSymbol: tokenData[0],
                    stakeTokenDecimals: tokenData[1],
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        if (mc) {
            fetch();
        }
        else {
            setStats({
                maxStakeableToken: 0,
                minimumStakeToken: 0,
                totalClaimedRewardToken: 0,
                totalStakedToken: 0,
                totalStakers: 0,
                totalUnStakedToken: 0,
                stakeTokenAddress: '',
                stakeTokenSymbol: '',
                stakeTokenDecimals: 0,
            })
        }
        // eslint-disable-next-line
    }, [updater]);

    return stats;
}


export const useRefStats = () => {
    let web3 = getWeb3();


    const [stats, setStats] = useState({
        l1: 0,
        l2: 0,
        l3: 0,
        l4: 0,
        l5: 0,

    });

    const mc = MulticallContractWeb3();
    let stakeContract = new web3.eth.Contract(bscstakeAbi, contract[BSC_CHAIN_ID].STAKE_ADDRESS);



    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await mc.aggregate([
                    stakeContract.methods.refLevelRate(0), //0
                    stakeContract.methods.refLevelRate(1), //0
                    stakeContract.methods.refLevelRate(2), //0
                    stakeContract.methods.refLevelRate(3), //0
                    stakeContract.methods.refLevelRate(4), //0
                ]);



                setStats({
                    l1: data[0] / 100,
                    l2: data[1] / 100,
                    l3: data[2] / 100,
                    l4: data[3] / 100,
                    l5: data[4] / 100,
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        if (mc) {
            fetch();
        }
        else {
            setStats({
                l1: 0,
                l2: 0,
                l3: 0,
                l4: 0,
                l5: 0,
            })
        }
        // eslint-disable-next-line
    }, []);

    return stats;
}
