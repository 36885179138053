import { Accordion, Col, Container, Row } from "react-bootstrap";
import circlePng from '../Assets/img/circles.png';
import { FAQs } from "../hooks/constant";


function Faqs() {
    return (
        <>
            <section className="faq-section">
                <div className="bg-circles"><img src={circlePng} alt="circle" /></div>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <Accordion defaultActiveKey={0}>
                                {FAQs.map((faq, index) => (
                                    <Accordion.Item key={index} eventKey={index.toString()}>
                                        <Accordion.Header>{faq.items}</Accordion.Header>
                                        <Accordion.Body>{faq.value}</Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Faqs