import { useEffect, useState } from "react";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import { BSC_CHAIN_ID, BSC_STAKE_APY, INIT_AMOUNT, INIT_USER, contract, formatDateWithZone } from "../hooks/constant";
import { formatPrice, getContract } from "../hooks/contractHelper";
import { useCommonStats } from "../hooks/useBscCommon";
import { useAccountStats } from "../hooks/useBscAccount";
import { toast } from 'react-toastify';
import tokenAbi from '../json/token.json';
import stakeAbi from '../json/bscstake.json';
import { ethers } from "ethers";
import { getWeb3 } from "../hooks/connectors";
import Refsection from "./Refsection";
import curveLeft from '../Assets/img/why-token-curve-left.png'
import curveRight from '../Assets/img/why-token-curve-right.png'
import bscIcon from '../Assets/img/bsc-icon.png'
import { useLocation } from "react-router-dom";
import { useAccount, useNetwork } from 'wagmi';
import { useEthersSigner } from "../hooks/useEthersProvider";
import { useWeb3Modal } from '@web3modal/react';




function Home() {
    const { open } = useWeb3Modal()
    const { address } = useAccount();
    const { chain } = useNetwork();
    const signer = useEthersSigner()
    const [updater, setUpdater] = useState(new Date());
    const commonStats = useCommonStats(updater);
    const accStats = useAccountStats(updater);
    const [selectedAPY, setSelectedAPY] = useState(0);
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState(0);
    const [error, setError] = useState('');
    const timeElapsed = Date.now();
    const [endtime, setEndtime] = useState(new Date(parseInt(timeElapsed) + parseInt(BSC_STAKE_APY[0].time * 86400 * 1000)));
    const [refAddress, setRefAddress] = useState('');
    const search = useLocation().search;

    useEffect(() => {
        let refAddr = '';
        const queryChainId = new URLSearchParams(search).get('ref');
        if (queryChainId !== '') {
            refAddr = queryChainId;
        }
        setRefAddress(refAddr);
        // eslint-disable-next-line
    }, [])

    const handleChangeAPY = (e, index) => {
        e.preventDefault();
        setSelectedAPY(index);
        setEndtime(new Date(parseInt(timeElapsed) + parseInt(BSC_STAKE_APY[index].time * 86400 * 1000)))
    }

    const handleApprove = async () => {
        if (address) {
            if ( chain && chain.id && parseInt(chain.id) === parseInt(BSC_CHAIN_ID) && commonStats.stakeTokenAddress !== '') {
                try {

                    setLoading(true);
                    let stakingContract = contract[BSC_CHAIN_ID].STAKE_ADDRESS;
                    let stakeContract = getContract(tokenAbi, commonStats.stakeTokenAddress, signer);
                    let amount = ethers.utils.parseEther('100000000000000000').toString();
                    let tx = await stakeContract.approve(stakingContract, amount, { 'from': address });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(BSC_CHAIN_ID);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');
                                setLoading(false);
                                setUpdater(new Date());
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                                setUpdater(new Date());
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                                setUpdater(new Date());
                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    console.log(err.message)
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading(false);
                }
            }
            else {
                toast.error('Please select Smart Chain Network !');
                setLoading(false);
            }

        }
        else {
            toast.error('Please Connect Wallet!');
            setLoading(false);
        }
    }

    const handleChangeAmount = (e) => {
        e.preventDefault();
        setAmount(e.target.value);
        if (isNaN(e.target.value)) {
            setError('Please enter valid amount.');
        }
        else if (parseFloat(e.target.value) < parseFloat(commonStats.minimumStakeToken) || parseFloat(e.target.value) > parseFloat(commonStats.maxStakeableToken)) {
            setError(`Amount must be greater than ${commonStats.minimumStakeToken} and less than ${commonStats.maxStakeableToken}`);
        }
        else {
            setError('');
        }
        return;
    }

    const handleMaxAmount = () => {
        parseFloat(accStats.stakeTokenBalance) > parseFloat(commonStats.maxStakeableToken) ?
            setAmount(commonStats.maxStakeableToken) : setAmount(accStats.stakeTokenBalance);
        setError('');
    }

    const handleStake = async () => {
        setLoading(true);
        try {
            if (amount > 0 && !isNaN(amount) && parseFloat(amount) >= parseFloat(commonStats.minimumStakeToken) && parseFloat(amount) <= parseFloat(commonStats.maxStakeableToken)) {
                if (address) {
                    if (chain && chain.id && parseInt(chain.id) === parseInt(BSC_CHAIN_ID)) {
                        if (parseFloat(accStats.stakeTokenBalance) >= parseFloat(amount)) {
                            let tokenStakingAddress = contract[BSC_CHAIN_ID].STAKE_ADDRESS;
                            let stakeContract = getContract(stakeAbi, tokenStakingAddress, signer);
                            let stakeAmount = ethers.utils.parseUnits(amount.toString(), accStats.stakeTokenDecimals);
                            let refAddr = refAddress ? refAddress : '0x0000000000000000000000000000000000000000';
                            let tx = await stakeContract.stake(stakeAmount.toString(), selectedAPY, refAddr , { 'from': address });
                            const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                            toast.promise(
                                resolveAfter3Sec,
                                {
                                    pending: 'Waiting for confirmation 👌',
                                }
                            )

                            var interval = setInterval(async function () {
                                let web3 = getWeb3();
                                var response = await web3.eth.getTransactionReceipt(tx.hash);
                                if (response != null) {
                                    clearInterval(interval)
                                    if (response.status === true) {
                                        toast.success('success ! your last transaction is success 👍');
                                        setUpdater(new Date());
                                        setLoading(false);
                                    }
                                    else if (response.status === false) {
                                        toast.error('error ! Your last transaction is failed.');
                                        setUpdater(new Date());
                                        setLoading(false);
                                    }
                                    else {
                                        toast.error('error ! something went wrong.');
                                        setUpdater(new Date());
                                        setLoading(false);
                                    }
                                }
                            }, 5000);

                        }
                        else {
                            toast.error('you don\'t have enough balance !');
                            setLoading(false);
                        }

                    }
                    else {
                        toast.error('Please select Smart Chain Network !');
                        setLoading(false);
                    }
                }
                else {
                    toast.error('Please Connect Wallet!');
                    setLoading(false);
                }
            }
            else {
                toast.error('Please Enter Valid Amount !');
                setLoading(false);
            }
        }
        catch (err) {
            toast.error(err.reason);
            setLoading(false);
        }
    }

    const handleUnStake = async (index) => {

        try {

            if (address) {
                if (chain && chain.id && parseInt(chain.id) === parseInt(BSC_CHAIN_ID)) {
                    let tokenStakingAddress = contract[BSC_CHAIN_ID].STAKE_ADDRESS;
                    let stakeContract = getContract(stakeAbi, tokenStakingAddress, signer);


                    let tx = await stakeContract.unstake(index, { 'from': address });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation 👌',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(BSC_CHAIN_ID);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');
                                setUpdater(new Date());
                                setLoading(false);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(new Date());
                                setLoading(false);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(new Date());
                                setLoading(false);
                            }
                        }
                    }, 5000);



                }
                else {
                    toast.error('Please select Smart Chain Network !');
                    setLoading(false);
                }
            }
            else {
                toast.error('Please Connect Wallet!');
                setLoading(false);
            }

        }
        catch (err) {
            toast.error(err.reason);
            setLoading(false);
        }
    }

    const handleHarvest = async (index) => {
        try {

            if (address) {
                if (chain && chain.id && parseInt(chain.id) === parseInt(BSC_CHAIN_ID)) {
                    let tokenStakingAddress = contract[BSC_CHAIN_ID].STAKE_ADDRESS;
                    let stakeContract = getContract(stakeAbi, tokenStakingAddress, signer);

                    let tx = await stakeContract.harvest(index, { 'from': address });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation 👌',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3();
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');
                                setUpdater(new Date());
                                setLoading(false);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(new Date());
                                setLoading(false);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(new Date());
                                setLoading(false);
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please select Smart Chain Network !');
                    setLoading(false);
                }
            }
            else {
                toast.error('Please Connect Wallet!');
                setLoading(false);
            }

        }
        catch (err) {
            toast.error(err.reason);
            setLoading(false);
        }
    }

    function getMaxAPY() {
        let maxAPY = -Infinity; // Start with a very low value
        for (const stake of BSC_STAKE_APY) {
            const apy = parseFloat(stake.apy); // Convert string to number
            if (apy > maxAPY) {
                maxAPY = apy;
            }
        }
        return maxAPY;
    }

    return (
        <>
            <section className="banner-section">
                <div className="bg-layer"></div>
                <Container>
                    <Row>
                        <Col xs={12} md={6} lg={7}>
                            <h1>CHC Staking Dashboard</h1>
                            <Button className="theme-btn">Stake CHC</Button>
                            <h4>Powered by <img src={bscIcon} alt="" /> <span>BSC</span></h4>
                        </Col>
                        <Col xs={12} md={6} lg={5}>
                            <div className="stake-box">
                                <div className="staking-info">
                                    <div className="all-info">
                                        <div className="label-1">CHC STAKED</div>
                                        <div className="credit-stacked">{commonStats.totalStakedToken || INIT_AMOUNT ? formatPrice( parseFloat(INIT_AMOUNT) + parseFloat(commonStats.totalStakedToken)) : 0} <span>{commonStats.stakeTokenSymbol ? commonStats.stakeTokenSymbol : ' - '}</span></div>
                                    </div>
                                    <div className="all-info">
                                        <div className="label-1">CHC STAKERS</div>
                                        <div className="credit-stacked">{commonStats.totalStakers ? formatPrice( parseInt(INIT_USER) + parseInt(commonStats.totalStakers)) : 0}</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="calculator-section">
                <div className="curve-left">
                    <img src={curveLeft} alt="curve-left" />
                </div>
                <div className="curve-right">
                    <img src={curveRight} alt="curve-right" />
                </div>
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={12} md={8} lg={8}>
                            <div className="calc-box">
                                <h3>$CHC Calculator</h3>
                                <p>Stake CHC to earn CHC rewards up to {getMaxAPY()}% APY.</p>
                                <div className="add-liquidity">
                                    <div className="content">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="labels">
                                                <h6>CHC</h6>
                                            </div>
                                            <div className="labels d-flex">
                                                <input type="text" value={amount} onChange={(e) => handleChangeAmount(e)} placeholder="5000" />
                                                <Button className="input-button" onClick={() => handleMaxAmount()}>Max</Button>
                                            </div>

                                        </div>
                                        <span style={{"fontSize" : "14px"}} className='text-danger'>{error}</span>
                                    </div>
                                </div>
                                <div className="plus-sys">+</div>
                                <div className="add-liquidity-2">
                                    <p>Lock tokens for</p>
                                    <div className="text-center">
                                        {BSC_STAKE_APY.map((rowdata, index) => {
                                            return (
                                                <button key={index} onClick={(e) => handleChangeAPY(e, index)} className={`btn-box ${rowdata.apy === BSC_STAKE_APY[selectedAPY].apy ? 'active' : ''}`}>
                                                    {rowdata.time ? rowdata.time : ' - '} Days
                                                </button>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="plus-sys">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><polyline points="19 12 12 19 5 12"></polyline></svg>
                                </div>
                                <h4>Upto {BSC_STAKE_APY[selectedAPY].apy}% Returns on {BSC_STAKE_APY[selectedAPY].time} Days</h4>
                                <h4>locked until {formatDateWithZone(endtime)}</h4>
                                {address ? (
                                    parseFloat(accStats.isApproved) >= parseFloat(amount) ? (
                                        <Button className="theme-btn w-100 mt-3" onClick={() => !loading ? handleStake() : null}>{loading ? 'Loading…' : 'Stake'}</Button>
                                    ) : (
                                        <Button className="theme-btn w-100 mt-3" onClick={() => !loading ? handleApprove() : null}>{loading ? 'Loading…' : 'Approve'}</Button>
                                    )
                                ) : (
                                    <Button type="button" onClick={() => open()} className="theme-btn w-100 mt-3">Connect Wallet</Button>
                                )}

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            
            <section className="details-section">
                <Container>
                    <Row>
                        <Col sm={12} md={6} lg={4}>
                            <div className="details-box">
                                <div className="title">CHC Earned</div>
                                <h4>{accStats.totalClaimedRewardTokenUser ? formatPrice(accStats.totalClaimedRewardTokenUser) : 0}</h4>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <div className="details-box">
                                <div className="title">Your CHC Wallet Balance</div>
                                <h4>{accStats.stakeTokenBalance ? formatPrice(accStats.stakeTokenBalance) : 0}</h4>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <div className="details-box">
                                <div className="title">Claimed Reward</div>
                                <h4>{accStats.totalClaimedRewardTokenUser ? formatPrice(accStats.totalClaimedRewardTokenUser) : 0}</h4>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <div className="details-box">
                                <div className="title">Current Staked</div>
                                <h4>{accStats.currentStake ? formatPrice(accStats.currentStake) : 0}</h4>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <div className="details-box">
                                <div className="title">Total Staked</div>
                                <h4>{accStats.totalStakedTokenUser ? formatPrice(accStats.totalStakedTokenUser) : 0}</h4>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <div className="details-box">
                                <div className="title">Total UnStaked</div>
                                <h4>{accStats.totalUnstakedTokenUser ? formatPrice(accStats.totalUnstakedTokenUser) : 0}</h4>
                            </div>
                        </Col>
                    </Row>
                    <Refsection accStats={accStats}  />
                    <Row className="mt-5">
                        <Col sm={12}>
                            <h3 className="text-white">Transctions History</h3>
                            <Table bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Staked Amount</th>
                                        <th>Stake Date</th>
                                        <th>Unstake Date</th>
                                        <th>Earn Reward</th>
                                        <th>Unstake</th>
                                        <th>Harvest</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {accStats.totalStakeHistory.length > 0 ? (
                                        accStats.totalStakeHistory.map((rowdata, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{rowdata[2] ? formatPrice(rowdata[2] / Math.pow(10, accStats.stakeTokenDecimals)) : 0}</td>
                                                    <td>{rowdata[1] ? formatDateWithZone(rowdata[1] * 1000) : ' - '}</td>
                                                    <td>{rowdata[0] ? formatDateWithZone(rowdata[0] * 1000) : ' - '}</td>
                                                    <td>{accStats.rewardHistory[index] ? parseFloat(accStats.rewardHistory[index][0] / Math.pow(10, accStats.stakeTokenDecimals)).toFixed(5) : 0}</td>
                                                    <td><Button onClick={() => handleUnStake(index)} disabled={rowdata[10]} className="theme-btn">Unstake</Button></td>
                                                    <td><Button onClick={() => handleHarvest(index)} disabled={rowdata[10]} className="theme-btn">Harvest</Button></td>
                                                </tr>
                                            )
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={6}>
                                                <h5 className="m-0 text-white">You Have No Stake Record Yet.</h5>
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container >
            </section >
        </>
    )
}

export default Home
